import axios from 'axios';
import store from '../store/store';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import tools from './tools';
//import router from '../router';

const axiosInstance = axios.create({});

const axiosProtectedInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: { "Content-type": "application/json; charset=utf-8" }
});
axiosProtectedInstance.defaults.timeout = 60000;

const axiosChatbotInstance = axios.create({
    baseURL: process.env.VUE_APP_CHATBOT_URL,
    headers: { "Content-type": "application/json; charset=utf-8" }
});
axiosChatbotInstance.interceptors.request.use(config => {
    if (store.getters['Authn/authToken']) {
        config.headers.Authorization = 'Bearer ' + store.getters['Authn/authToken'];
    }
    config.timeout = 30000;
    return config;
});


const cmsVersionHeader = 'x-cms-version';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY || 'missing-firebase-api-key',
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || 'missing-firebase-auth-domain',
    databaseUrl: process.env.VUE_APP_DATABASE_URL || 'missing-firebase-database-url',
    projectId: process.env.VUE_APP_PROJECT_ID || 'missing-firebase-project-id',
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET || 'missing-firebase-storage-bucket',
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID || 'missing-firebase-messaging-sender-id',
    appId: process.env.VUE_APP_APP_ID || 'missing-firebase-app-id',
};
const firebase = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebase);
const firebaseGoogleProvider = new GoogleAuthProvider();

const firebaseApi = axios.create({
    baseURL: firebaseConfig.databaseUrl,
});

const logout = () => {
    return new Promise((resolve, reject) => {
        signOut(firebaseAuth).then(() => {
            // Sign-out successful.
            resolve();
        }).catch((error) => {
            // An error happened.
            reject(error);
        });
    })
}

axiosProtectedInstance.interceptors.request.use(config => {
    if (store.getters['Authn/authToken']) {
        config.headers.Authorization = 'Bearer ' + store.getters['Authn/authToken'];
    }
    config.headers[cmsVersionHeader] = process.env.VUE_APP_VERSION; //TODO change to match calculated version
    config.timeout = 30000;
    return config;
});

axiosProtectedInstance.interceptors.response.use(res => {
    return res;
}, err => {
    if (err.response.status === 403) {

        store.dispatch('Authn/logout').then(() => {
        });
    }
    if (err.response.status === 410 || err.response.status === 401) {
        //Expired token
        store.dispatch('Authn/logout').then(() => {
        });
    }
    return Promise.reject(err);
});

if (process.env.VUE_APP_DEBUG) {
    axiosInstance.interceptors.request.use(c => {
        console.log('Axios REQUEST:', c);
        return c;
    });
    axiosInstance.interceptors.response.use(response => {
        console.log('Axios RESPONSE:', response);
        return response;
    });
    firebaseApi.interceptors.request.use(c => {
        console.log('ToFirebase REQUEST:', c);
        return c;
    });
    firebaseApi.interceptors.response.use(response => {
        console.log('ToFirebase RESPONSE:', response);
        return response;
    });
    axiosProtectedInstance.interceptors.request.use(c => {
        console.log('Axios PROTECTED REQUEST:', c);
        return c;
    });
    axiosChatbotInstance.interceptors.request.use(c => {
        console.log('Chatbot REQUEST:', c);
        return c;
    });

}

const debug = (...args) => {
    if (process.env.VUE_APP_DEBUG) {
        console.log('DEBUG::', args);
    }
};

const formatError = (short, err) => {
    return {
        error: short,
        response: err.response,
    };
};

const newErr = ({ msg }) => {
    return {
        data: {
            error: {
                message: msg,
            },
        },
    };
};

const getUrl = endpoint => endpoint;

const emptyError = (short, long) => {
    return formatError(short, { response: newErr({ msg: long }) });
};

const getS3Rel = (destFolder, lang) => {
    const d = new Date();
    let base = destFolder + '/';
    base = base.replace('%Y', '' + d.getFullYear());
    const month = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
    base = base.replace('%M', month);
    if (lang) {
        base = base.replace('%L', lang);
    } else {
        base = base.replace('%L', '--lingua--');
    }
    return base;
};

export default {
    debug,
    getUrl,
    withGetParams: (url, params) => {
        if (typeof params === 'object') {
            const keys = Object.keys(params);
            if (keys.length) {
                let c = 0;
                keys.forEach(k => {
                    url += (c > 0) ? '&' : '?';
                    url += k + '=' + encodeURIComponent(params[k]);
                    c++;
                });
            }
        }
        debug('URL-PARAMS', params, 'URL', url);
        return url;
    },
    firebase,
    logout: logout,
    loginWithGoogle: () => {
        return new Promise((resolve, reject) => {
            signInWithPopup(firebaseAuth, firebaseGoogleProvider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    resolve({
                        token,
                        user,
                        credential,
                    });
                    // ...
                }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                reject({
                    errorCode,
                    errorMessage,
                    email,
                    credential,
                });
                // ...
            });
        });
    },
    firebaseApi,
    formatError,
    newErr,
    emptyError,
    firebaseEp: (context, url) => {
        const token = context.getters.authToken;
        const userId = context.getters.authUserId;
        return url + '/' + userId + '.json?auth=' + token;
    },
    client: axiosInstance,
    chatbotClient: axiosChatbotInstance,
    api: {
        client: axiosProtectedInstance,
        getBaseUrl: () => {
            return process.env.VUE_APP_API_URL;
        },
        cmsVersionHeader,
        listPromise(context, config, ep, params = null){
            let qs = null;
            if (params !== null){
                qs = {
                    params: params
                };
            }
            return new Promise((resolve, reject) => {
                axiosProtectedInstance.get(ep, qs)
                    .then(res => {
                        if (res && res['data'] && res['data'][config.respEls]) {
                            if (config.storeRows) {
                                context.commit(config.storeRows, res['data'][config.respEls]);
                            }
                            resolve(res['data'][config.respEls]);
                        } else {
                            reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        }
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                        if (config.stopLoading) {
                            context.commit(config.stopLoading);
                        }
                    });
            });
        },
        boolDonePromise(context, config, ep, payload){
            return new Promise((resolve, reject) => {
                context.commit('startLoading');
                axiosProtectedInstance.post(ep, payload)
                    .then(res => {
                        if (res && res.data && res.data['done']){
                            resolve();
                            return;
                        }
                        reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                        context.commit('stopLoading');
                    });
            });
        },
        boolPatchDonePromise(context, config, ep, payload){
            return new Promise((resolve, reject) => {
                context.commit('startLoading');
                axiosProtectedInstance.patch(ep, payload)
                    .then(res => {
                        if (res && res.data && res.data['done']){
                            resolve();
                            return;
                        }
                        reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                        context.commit('stopLoading');
                    });
            });
        },
        boolSimpleDeletePromise(context, config, ep){
            return new Promise((resolve, reject) => {
                context.commit('startLoading');
                axiosProtectedInstance.delete(ep)
                    .then(res => {
                        if (res && res.data && res.data['done']){
                            resolve();
                            return;
                        }
                        reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                        context.commit('stopLoading');
                    });
            });
        },
        fetchOne(context, config, id) {
            tools.report('FETCHING ', id);
            if (config.storeRow) {
                context.commit(config.storeRow, {});
            }
            if (config.startLoading) {
                context.commit(config.startLoading);
            }
            const ep = getUrl(config.ep) + '/' + id;
            tools.report('EP ', ep);

            return new Promise((resolve, reject) => {
                axiosProtectedInstance.get(ep)
                    .then(res => {
                        if (res && res['data'] && res['data'][config.respEl]) {
                            if (config.storeRow) {
                                context.commit(config.storeRow, res['data'][config.respEl]);
                            }
                            resolve(res['data'][config.respEl]);
                        } else {
                            reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        }
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                            if (config.stopLoading) {
                                context.commit(config.stopLoading);
                            }
                        },
                    );
            });
        },
        saveTranslation: (context, config, data, parent_id) => {
            const locKey = config.localeKey || 'locale';
            const ep = getUrl(config.ep) + '/' + parent_id + '/translation';
            if (!data[locKey]) {
                data[locKey] = 'it';
            }
            if (config.startLoading) {
                context.commit(config.startLoading);
            }
            let payload = {};
            if (config.translationKey) {
                payload[config.translationKey] = data;
            } else {
                payload['translation'] = data;
            }

            return new Promise((resolve, reject) => {
                axiosProtectedInstance.patch(ep, JSON.stringify(payload), {
                    headers: {
                        'Accept-language': data[locKey],
                    },
                })
                    .then(res => {
                        if (res && res['data'] && res['data'][config.respEl]) {
                            if (config.storeRow) {
                                context.commit(config.storeRow, res['data'][config.respEl]);
                            }
                            resolve(res['data'][config.respEl]);
                        } else {
                            reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        }
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                        if (config.stopLoading) {
                            context.commit(config.stopLoading);
                        }
                    });
            });
        },
        savePlain: (context, config, data) => {
            const ep = getUrl(config.ep) + '/' + data.id;
            tools.report('EP ', ep);
            if (config.startLoading) {
                context.commit(config.startLoading);
            }
            let payload = {};
            if (config.reqEl) {
                payload[config.reqEl] = data;
            } else {
                payload = data;
            }
            let remoteCall = axiosProtectedInstance.patch;
            if (config.methods && config.methods.savePlain) remoteCall = axiosProtectedInstance[config.methods.savePlain];

            return new Promise((resolve, reject) => {
                remoteCall(ep, JSON.stringify(payload))
                    .then(res => {
                        if (res && res['data'] && res['data'][config.respEl]) {
                            if (config.storeRow) {
                                context.commit(config.storeRow, res['data'][config.respEl]);
                            }
                            resolve(res['data'][config.respEl]);
                        } else {
                            reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        }
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                        if (config.stopLoading) {
                            context.commit(config.stopLoading);
                        }
                    });
            });
        },
        createPlain: (context, config, data) => {
            const ep = getUrl(config.ep);
            if (config.startLoading) {
                context.commit(config.startLoading);
            }
            let payload = {};
            if (config.reqEl) {
                payload[config.reqEl] = data;
            } else {
                payload = data;
            }

            return new Promise((resolve, reject) => {
                axiosProtectedInstance.post(ep, JSON.stringify(payload))
                    .then(res => {
                        if (res && res['data'] && res['data'][config.respEl]) {
                            if (config.storeRow) {
                                context.commit(config.storeRow, res['data'][config.respEl]);
                            }
                            resolve(res['data'][config.respEl]);
                        } else {
                            reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        }
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                        if (config.stopLoading) {
                            context.commit(config.stopLoading);
                        }
                    });
            });
        },
        fetchList(context, config, queryParams) {
            if (config.startLoading) {
                context.commit(config.startLoading);
            }

            const ep = config['fetchListEp'] ? getUrl(config['fetchListEp'], queryParams) : getUrl(config.ep);

            const qp = queryParams ? {params: queryParams} : null;

            return new Promise((resolve, reject) => {
                if (config.storeRows) {
                    context.commit(config.storeRows, []);
                }
                axiosProtectedInstance.get(ep, qp)
                    .then(res => {
                        if (res && res['data'] && res['data'][config.respEls]) {
                            if (config.storeRows) {
                                context.commit(config.storeRows, res['data'][config.respEls]);
                            }
                            resolve(res['data'][config.respEls]);
                        } else {
                            reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        }
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                        if (config.stopLoading) {
                            context.commit(config.stopLoading);
                        }
                    });
            });
        },
        postPromise(context, config, ep, payload){
            return new Promise((resolve, reject) => {
                axiosProtectedInstance.post(ep, JSON.stringify(payload))
                    .then(res => {
                        if (res && res.data && res.data[config.respEls]){
                            context.commit(config.storeRows, res.data[config.respEls]);
                            resolve(res.data[config.respEls]);
                            return;
                        }
                        reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                    })
                    .catch(err => {
                        reject(formatError('server-error', err));
                    })
                    .finally(() => {
                        context.commit('stopLoading');
                    });
            })
        },
        getPresignedUrl(file){
            const ep = getUrl("/v1/presigned-url-get")
            const bucket = process.env.VUE_APP_S3_BUCKET
            return new Promise((resolve, reject) => {
                axiosProtectedInstance.post(ep, {
                    bucket: bucket,
                    key: file
                }).then(res => {
                    if (res && res['data'] && res['data']['url']) {
                        resolve(res['data']['url']);
                    } else {
                        reject(emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                    }
                }).catch(err => {
                    reject(formatError('server-error', err));
                });
            });
        },
        getS3Params(file) {
            const url = process.env.VUE_APP_API_URL + process.env.VUE_APP_PRESIGNED_S3_API_UPLOAD_EP;
            const bucket = process.env.VUE_APP_S3_BUCKET;
            return fetch(url, {
                method: 'post',
                // Send and receive JSON.
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json; charset=utf-8',
                    Authorization: 'Bearer ' + store.getters['Authn/authToken'],
                    [cmsVersionHeader]: process.env.VUE_APP_VERSION,
                },
                body: JSON.stringify({
                    key: file.name,
                    bucket: bucket,
                }),
            })
                .then(response => {
                    // Parse the JSON response.
                    //Controllare lo stato, se non è 200 occorre mostrare errore
                    return response.json();
                })
                .then(data => {
                    // Return an object in the correct shape.
                    return {
                        method: 'PUT',
                        url: data.url,
                        fields: {},
                    };
                });
        },
        getS3Rel,
        getS3dir: (destFolder, lang) => {
            return process.env.VUE_APP_S3_BUCKET_URL + getS3Rel(destFolder, lang) ;
        },
        getS3bucket: () => {
            return process.env.VUE_APP_S3_BUCKET_URL;
        },
    },
};
