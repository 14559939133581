import http from '../../../libs/http';
import tools from '../../../libs/tools';

const config = {
    ep: '/v1/remote_configs',
    reqEl: 'config',
    respEls: 'configs',
    respEl: 'config',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setAll',
    storeRow: 'setRow',
    localeKey: 'locale',
    methods: {
        savePlain: 'post',
    },
};

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                android: [],
                web: [],
                ios: [],
                others: [],
                loading: false,
                prefixes: {
                    web: "web::",
                    android: "android::",
                    ios: "ios::",
                    "": ""
                }
            },
            getters: {
                ...standardStore.getters,
                android: state => state.android,
                web: state => state.web,
                ios: state => state.ios,
                others: state => state.others,
                prefixes: state => state.prefixes,
            },
            mutations: {
                ...standardStore.mutations,
                setAll: (state, all) => {
                    const android = [];
                    const ios = [];
                    const others = [];
                    const web = [];
                    const prefixes = state.prefixes;
                    all.forEach((item) => {
                        const key = item["param"];
                        if (item["date_val"]){
                            item["date_val_c"] = tools.time.api2Date(item["date_val"])
                        }
                        if (key.startsWith(prefixes.web)) {
                            item["key"] = key.replace(prefixes.web, "");
                            item["prefix"] = prefixes.web;
                            web.push(item);
                        } else if (key.startsWith(prefixes.ios)) {
                            item["key"] = key.replace(prefixes.ios, "");
                            item["prefix"] = prefixes.ios;
                            ios.push(item);
                        } else if (key.startsWith(prefixes.android)) {
                            item["key"] = key.replace(prefixes.android, "");
                            item["prefix"] = prefixes.android;
                            android.push(item);
                        } else {
                            item["key"] = key;
                            item["prefix"] = "";
                            others.push(item);
                        }
                    })
                    state.android = android;
                    state.web = web;
                    state.ios = ios;
                    state.others = others;
                    state.rows = all;
                },
            },
            actions: {
                ...standardStore.actions(config),
                save(context, item){
                    if (config.startLoading) {
                        context.commit(config.startLoading);
                    }
                    if (item["int_val"]) {
                        item["int_val"] = parseInt(item["int_val"]);
                    }
                    const payload = {
                        [config.reqEl]: item
                    }
                    return new Promise((resolve, reject) => {
                        http.api.client.post(config.ep, payload)
                            .then(res => {
                                if (res && res['data'] && res['data'][config.respEl]) {
                                    resolve(res['data'][config.respEl]);
                                } else {
                                    reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                                }
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                    if (config.stopLoading) {
                                        context.commit(config.stopLoading);
                                    }
                                },
                            );
                    });
                }
            },
        };
    },
};
