const View = () => import('../../views/web/remote_config/View');

export default {
    View: {
        path: 'web/remote_config',
        name: 'remote_config', //CHANGE
        exact: true,
        component: View,
        meta: {
            basePath: '/app/web/remote_config',
            breadcrumb: [{ label: 'Remote Config' }],
        },
    }
}
