import http from '../../../libs/http';

const config = {
    ep: 'v2/books',
    reqEl: 'book',
    respEls: 'books',
    respEl: 'book',
    startLoading: 'startLoading',
    stopLoading: 'stopLoading',
    storeRows: 'setRows',
    storeRow: 'setRow',
    localeKey: 'locale',
};

const askGPTContent = (context, { book_id, lang }, epPart, loadMutation, responseKey = 'content') => {
    const ep = '/v2/books/' + book_id + '/' + epPart;
    context.commit(loadMutation, true);
    return new Promise((resolve, reject) => {
        http.api.client.get(ep, {
            headers: {
                'Accept-language': lang,
            },
        })
            .then(res => {
                if (res && res.data && res.data[responseKey]) {
                    resolve(res.data);
                    return;
                }
                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
            })
            .catch(err => {
                reject(http.formatError('server-error', err));
            })
            .finally(() => {
                context.commit(loadMutation, false);
            });
    });
};
const askTweakedGPTContent = (context, {
    book_id,
    lang,
    prompt,
    temperature,
}, epPart, loadMutation, responseKey = 'content') => {
    const ep = '/v2/books/' + book_id + '/' + epPart + '/searches';
    context.commit(loadMutation, true);
    const payload = {
        prompt: prompt,
        temperature: temperature,
    };
    return new Promise((resolve, reject) => {
        http.api.client.post(ep, payload, {
            headers: {
                'Accept-language': lang,
            },
        })
            .then(res => {
                if (res && res.data && res.data[responseKey]) {
                    resolve(res.data);
                    return;
                }
                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
            })
            .catch(err => {
                reject(http.formatError('server-error', err));
            })
            .finally(() => {
                context.commit(loadMutation, false);
            });
    });
};

export default {
    init(standardStore) {
        return {
            namespaced: true,
            state: {
                ...standardStore.state,
                searched: [],
                keywordsLoading: false,
                summaryLoading: false,
                catchLineLoading: false,
                quizLoading: false,
            },
            getters: {
                ...standardStore.getters,
                searched: state => state.searched,
                keywordsLoading: state => state.keywordsLoading,
                summaryLoading: state => state.summaryLoading,
                catchLineLoading: state => state.catchLineLoading,
                quizLoading: state => state.quizLoading,
            },
            mutations: {
                ...standardStore.mutations,
                setSearched(state, values) {
                    if (values) state.searched = values;
                },
                clearSearch(state) {
                    state.searched = [];
                },
                setKeywordsLoading(state, value) {
                    state.keywordsLoading = value;
                },
                setSummaryLoading(state, value) {
                    state.summaryLoading = value;
                },
                setCatchLineLoading(state, value) {
                    state.catchLineLoading = value;
                },
                setQuizLoading(state, value) {
                    state.quizLoading = value;
                },
            },
            actions: {
                ...standardStore.actions(config),
                findSkillsAssociations(context, { books, lang }) {
                    const payload = {
                        books: books,
                    };
                    const baseUrl = http.api.getBaseUrl().replace('/cms', '/');
                    const ep = baseUrl + 'api/ms-1/matches/skills/by_books';
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, JSON.stringify(payload), {
                            headers: {
                                'Accept-language': lang,
                            },
                        })
                            .then(res => {
                                if (res && res.data && res.data['matches']) {
                                    resolve(res.data['matches']);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    });
                },
                downloadSkillsAssociations(context) {
                    const ep = '/v1/matches/skills/books';
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep)
                            .then(res => {
                                if (res && res.data) {
                                    resolve(res.data);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    });
                },
                search(context, params) {
                    context.commit('clearSearch');
                    context.commit('startLoading');
                    const sendData = {
                        text: '',
                        limit: 8,
                        categories: [],
                        tags: [],
                        progress: [],
                    };
                    if (params.text) sendData.text = params.text;
                    if (params.limit) sendData.limit = params.limit;
                    //Complete this function to consider other params if needed
                    const ep = '/v1/searches';
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, JSON.stringify(sendData))
                            .then(res => {
                                if (res && res.data && res.data[config.respEls]) {
                                    context.commit('setSearched', res.data[config.respEls]);
                                    resolve(res.data[config.respEls]);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit('stopLoading');
                            });
                    });
                },
                searchKeywords(context, { book_id, lang, prompt, temperature }) {
                    const ep = '/v2/books/' + book_id + '/keywords';

                    context.commit('setKeywordsLoading', true);
                    return new Promise((resolve, reject) => {
                        if (prompt) {
                            const payload = {};
                            payload['prompt'] = prompt;
                            payload['temperature'] = temperature;
                            http.api.client.post(ep + '/searches', payload, {
                                headers: {
                                    'Accept-language': lang,
                                },
                            }).then(res => {
                                if (res && res.data && res.data['keywords']) {
                                    resolve(res.data);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                                .catch(err => {
                                    reject(http.formatError('server-error', err));
                                })
                                .finally(() => {
                                    context.commit('setKeywordsLoading', false);
                                });
                        } else {
                            http.api.client.get(ep, {
                                headers: {
                                    'Accept-language': lang,
                                },
                            }).then(res => {
                                if (res && res.data && res.data['keywords']) {
                                    resolve(res.data);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                                .catch(err => {
                                    reject(http.formatError('server-error', err));
                                })
                                .finally(() => {
                                    context.commit('setKeywordsLoading', false);
                                });
                        }
                    });
                },
                searchSummary(context, { book_id, lang, prompt, temperature }) {
                    if (prompt) {
                        return askTweakedGPTContent(context, {
                            book_id,
                            lang,
                            prompt,
                            temperature,
                        }, 'summary', 'setSummaryLoading');
                    }
                    return askGPTContent(context, { book_id, lang }, 'summary', 'setSummaryLoading');
                },
                searchCatchline(context, { book_id, lang, prompt, temperature }) {
                    if (prompt) {
                        return askTweakedGPTContent(context, {
                            book_id,
                            lang,
                            prompt,
                            temperature,
                        }, 'catchline', 'setCatchLineLoading');
                    }
                    return askGPTContent(context, { book_id, lang }, 'catchline', 'setCatchLineLoading');
                },
                createQuiz(context, { book_id, lang }) {
                    return askGPTContent(context, { book_id, lang }, 'quiz', 'setQuizLoading', 'quiz');
                },
                getKnowledgeCount(context, {lang}) {
                    context.commit(config.startLoading, true);
                    const ep = '/v1/chatbots/coach/books/knowledge/discover';
                    return new Promise((resolve, reject) => {
                        http.api.client.get(ep, { params: { lang: lang } })
                            .then(res => {
                                if (res && res.data && res.data['books']) {
                                    resolve(res.data['books']);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit(config.stopLoading, false);
                            });
                    });
                },
                requestKnowledgeSend(context, {lang, num}) {
                    context.commit(config.startLoading, true);
                    if (!num) num = 10;
                    const ep = '/v1/chatbots/coach/books/knowledge/discover';
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, {},{ params: { lang: lang, limit: num } })
                            .then(res => {
                                if (res && res.data) {
                                    resolve(res.data);
                                    return;
                                }
                                reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                            })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit(config.stopLoading, false);
                            });
                    });
                },
                saveKnowledge(context, { data, parent_id }) {
                    const payload = {
                        translation: {
                            ...data,
                        },
                    };
                    context.commit(config.startLoading, true);
                    const ep = '/v1/chatbots/coach/books/:book_id/translations'.replace(':book_id', parent_id);
                    return new Promise((resolve, reject) => {
                        http.api.client.post(ep, payload, {
                            headers: {
                                'Accept-language': data.locale,
                            },
                        }).then(res => {
                            if (res && res.data && res.data['rows']) {
                                resolve(res.data);
                                return;
                            }
                            reject(http.emptyError('no-data', 'Il server non ha fornito alcuna risposta'));
                        })
                            .catch(err => {
                                reject(http.formatError('server-error', err));
                            })
                            .finally(() => {
                                context.commit(config.stopLoading, false);
                            });
                    });
                },
            },
        };
    },
};
