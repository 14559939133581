import {createRouter, createWebHashHistory} from 'vue-router';

import ContCategoriesRoutes from './routes/cont/categories';
import ContTagsRoutes from './routes/cont/tags';
import ContAuthorsRoutes from './routes/cont/authors';
import ContPublishersRoutes from './routes/cont/publishers';
import ContQuotesRoutes from './routes/cont/quotes';
import ContSeriesRoutes from './routes/cont/bookseries';
import ContExpertsRoutes from './routes/cont/experts';
import ContSkillsRoutes from './routes/cont/skills';
import ContNewSkillsRoutes from './routes/cont/skillsV2';

import MagazineCategoriesRoutes from './routes/magazine/categories';
import MagazinePostsRoutes from './routes/magazine/posts';

import PsychologistsRoutes from './routes/psycho/psychologists';

import ApiDocRoutes from './routes/web/apidoc';
import WebOffersRoutes from './routes/web/offers';
import WebRedirectsRoutes from './routes/web/web_redirects';
import TaskImportSubRoutes from './routes/tasks/import_subscriptions';
import TaskImportPurchases from './routes/tasks/import_purchases';
import TaskImportSubB2bRoutes from './routes/tasks/import_company_subscriptions';
import TaskCouponAssignmentRoutes from './routes/tasks/import_gift_coupon_assignment';

import UsersRoutes from './routes/users/users';
import CmsUsersRoutes from './routes/users/cms';
import CompaniesRoutes from './routes/users/companies';
import CompanyTeamsRoutes from './routes/users/company_teams';
import CompanyUsersRoutes from './routes/users/company_users';
import DashboardB2BRoutes from './routes/users/dashboard_b2b';
import CompanyPathsRoutes from './routes/users/b2b_paths';
import TeamSkillsRoutes from './routes/users/team_skills';
import TeamPathsRoutes from './routes/users/team_paths';
import PodcastsRoutes from './routes/cont/podcasts';
import AbstractRoutes from './routes/cont/books';
import BooksRoutes from './routes/cont/complete_books';
import ArticlesRoutes from './routes/cont/articles';
import TheUpdateRoutes from './routes/cont/the_update';
import SpeakersRoutes from './routes/cont/speakers';
import CopywritersRoutes from './routes/cont/copywriters';

import AbtestsRoutes from './routes/analytics/abtests';

import PreviewRoutes from './routes/previews/routes';
import StatsSignupsLiveRoutes from './routes/stats/signups';
import ApiLiveStatsRoutes from './routes/stats/api';
import StatsCheckoutsLiveRoutes from './routes/stats/checkouts';
import StatsSubscriptionsHistoryRoutes from './routes/stats/subscriptions';
import BookQuizRoutes from './routes/cont/quizzes';
import ChatbotHelpdeskRoutes from './routes/chatbots/helpdesk';
import ChatbotCoachRoutes from './routes/chatbots/coach';
import DomainCheckerRoutes from './routes/web/domains';
import RemoteConfigRoutes from './routes/web/remote_config';
import store from './store/store';

const routes = [
    {
        path: '/previews',
        name: 'reserved_previews',
        component: () => import('./Empty.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            PreviewRoutes.BookPdf
        ]
    },
    {
        path: '/app',
        component: () => import('./Protected.vue'),
        name: 'reserved_area',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'home',
                name: 'dashboard',
                exact: true,
                component: () => import('./views/dashboard/Dashboard.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }],
                },
            },
            //CONT LOOKUPS CATEGORIES
            ChatbotCoachRoutes.Chat,
            RemoteConfigRoutes.View,
            DomainCheckerRoutes.Search,
            StatsSubscriptionsHistoryRoutes.View,
            ChatbotHelpdeskRoutes.List,
            ChatbotHelpdeskRoutes.Create,
            ChatbotHelpdeskRoutes.Update,
            PsychologistsRoutes.PatientsList,
            PsychologistsRoutes.List,
            PsychologistsRoutes.MyPatientsList,
            PsychologistsRoutes.SummaryStats,
            StatsSignupsLiveRoutes.View,
            ApiLiveStatsRoutes.View,
            StatsCheckoutsLiveRoutes.View,
            ContCategoriesRoutes.Create,
            ContCategoriesRoutes.Update,
            ContCategoriesRoutes.List,
            //CONT LOOKUPS TAGS
            ContTagsRoutes.Create,
            ContTagsRoutes.Update,
            ContTagsRoutes.List,
            //CONT LOOKUPS AUTHORS
            ContAuthorsRoutes.Create,
            ContAuthorsRoutes.Update,
            ContAuthorsRoutes.List,
            //CONT LOOKUPS PUBLISHERS
            ContPublishersRoutes.Create,
            ContPublishersRoutes.Update,
            ContPublishersRoutes.List,
            //CONT QUOTES
            ContQuotesRoutes.Create,
            ContQuotesRoutes.Update,
            ContQuotesRoutes.List,
            //CONT BOOKSERIES
            ContSeriesRoutes.Create,
            ContSeriesRoutes.Update,
            ContSeriesRoutes.List,
            //CONT EXPERTS
            ContExpertsRoutes.Create,
            ContExpertsRoutes.Update,
            ContExpertsRoutes.List,
            //Cont Podcasts
            PodcastsRoutes.Create,
            PodcastsRoutes.Update,
            PodcastsRoutes.List,
            //Quiz
            BookQuizRoutes.Update,
            BookQuizRoutes.List,
            //Skills
            ContSkillsRoutes.Create,
            ContSkillsRoutes.Update,
            ContSkillsRoutes.List,
            //New skills
            ContNewSkillsRoutes.Create,
            ContNewSkillsRoutes.Update,
            ContNewSkillsRoutes.List,
            //MAGAZINE MACROCATEGORIES
            MagazineCategoriesRoutes.Create,
            MagazineCategoriesRoutes.Update,
            MagazineCategoriesRoutes.List,
            //MAGAZINE POSTS
            MagazinePostsRoutes.Create,
            MagazinePostsRoutes.Update,
            MagazinePostsRoutes.List,
            //BOOKS
            AbstractRoutes.Create,
            AbstractRoutes.Update,
            AbstractRoutes.List,
            BooksRoutes.Create,
            BooksRoutes.Update,
            BooksRoutes.List,
            //ARTICLES
            ArticlesRoutes.Create,
            ArticlesRoutes.Update,
            ArticlesRoutes.List,
            //THE UPDATE
            TheUpdateRoutes.Create,
            TheUpdateRoutes.Update,
            TheUpdateRoutes.List,
            //Speakers
            SpeakersRoutes.Create,
            SpeakersRoutes.Update,
            SpeakersRoutes.List,
            //Copywriters
            CopywritersRoutes.Create,
            CopywritersRoutes.Update,
            CopywritersRoutes.List,
            //WEB
            // - Offers
            WebOffersRoutes.Create,
            WebOffersRoutes.Update,
            WebOffersRoutes.List,
            ApiDocRoutes.Update,
            AbtestsRoutes.Create,
            AbtestsRoutes.Compare,
            AbtestsRoutes.Update,
            AbtestsRoutes.List,
            WebRedirectsRoutes.Create,
            WebRedirectsRoutes.Update,
            WebRedirectsRoutes.List,
            //TASKS
            //Assign coupon
            TaskCouponAssignmentRoutes.Create,
            TaskCouponAssignmentRoutes.List,
            // - sub import
            TaskImportSubRoutes.Create,
            TaskImportSubRoutes.List,
            // - extra import
            TaskImportPurchases.Create,
            TaskImportPurchases.List,
            // - B2B sub
            TaskImportSubB2bRoutes.Create,
            TaskImportSubB2bRoutes.List,

            //USERS
            UsersRoutes.List,
            UsersRoutes.Update,
            CmsUsersRoutes.List,
            TeamSkillsRoutes.List,
            TeamPathsRoutes.List,
            CompanyUsersRoutes.List,
            CompanyUsersRoutes.ListPerTeam,
            CompanyTeamsRoutes.List,
            CompanyTeamsRoutes.Create,
            CompanyTeamsRoutes.Update,
            CompanyPathsRoutes.List,
            CompanyPathsRoutes.Create,
            CompanyPathsRoutes.Update,
            CompaniesRoutes.List,
            CompaniesRoutes.Create,
            CompaniesRoutes.Update,
            DashboardB2BRoutes.List
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/login/Login.vue')
    },
    {
        path: "/:catchAll(.*)",
        redirect: '/app/home'
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    console.log("TO", to);
    if (to.matched.some(record => record.meta['requiresAuth'])){
        const isLoggedIn = store.getters['Authn/loggedIn'];
        if (isLoggedIn) {
            //console.log("NEXT is Logged in");
            next();
        } else {
            next('/login');
            //console.log("GOTO LOGIN");
        }
        return;
    }
    //console.log("NEXT Auth not needed");
    next();
});
export default router;
