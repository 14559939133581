const Chat = () => import('../../views/cont/books/chatbot/Chatbot');


export default {
    Chat: {
        path: 'chatbots/coach',
        name: 'chatbots_coach_chat',
        exact: true,
        component: Chat,
        meta: {
            breadcrumb: [{ label: 'CHATBOTS' }, { label: 'Personal Coach' }, { label: 'Test Chat' }],
        },
    }
}
